
export default {
  name: 'NestedLinks',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    title: {
      default: '',
      required: false
    },
    // eslint-disable-next-line vue/require-prop-types
    items: {
      default: [],
      required: false
    },
    // eslint-disable-next-line vue/require-prop-types
    slotted: {
      default: false,
      required: false
    },
    // eslint-disable-next-line vue/require-prop-types
    hasIcon: {
      default: false,
      required: false
    },
    titleClass: {
      default: '',
      required: false
    }
  },
  data: () => ({ isActive: false }),
  methods: {
    showLinks () {
      if (this.$refs.nestedLinksList) { this.$refs.nestedLinksList.classList.add('active') }
      if (this.$refs.nestedLinksName) { this.$refs.nestedLinksName.classList.add('active') }
      this.isActive = true
    },
    hideLinks () {
      if (this.$refs.nestedLinksList) { this.$refs.nestedLinksList.classList.remove('active') }
      if (this.$refs.nestedLinksName) { this.$refs.nestedLinksName.classList.remove('active') }
      this.isActive = false
    },
    toggleLinks () {
      this.$emit('click')
      this.isActive ? this.hideLinks() : this.showLinks()
    },
    goTo (link, target) {
      if (target && target === 'blank') {
        window.open(`${window.location.origin}${link}`)
      } else {
        this.$emit('click')
        this.$router.push(link)
      }
    }
  }
}
